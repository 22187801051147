const Cities = {
  'saint-petersburg': {
    subzero: {
      name: 'city.spb.subzero.name',
      address: 'city.spb.subzero.address',
      phone: '+7 812 907 64 38',
      open: ['hours.mon-sun-12-00'],
      menu: {
        food: {
          en: 'https://subzerosushi.ru/static/files/spb/sz1-202407-food-ru.pdf',
          ru: 'https://subzerosushi.ru/static/files/spb/sz1-202407-food-ru.pdf',
        },
        alco: 'https://subzerosushi.ru/static/files/spb/sz1-202407-alco.pdf',
      },
      photos: {
        main: 'https://subzerosushi.ru/static/images/spb/subzero/sz1-001.jpg',
        gallery: [
          'https://subzerosushi.ru/static/images/spb/subzero/sz1-001.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero/sz1-002.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero/sz1-003.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero/sz1-004.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero/sz1-005.jpg',
        ],
      },
      map: {
        center: [59.92705, 30.34346],
      },
    },
    'subzero-2': {
      name: 'city.spb.subzero2.name',
      address: 'city.spb.subzero2.address',
      phone: '+7 812 927 64 38',
      open: ['hours.mon-sun-12-00'],
      menu: {
        food: {
          en: 'https://subzerosushi.ru/static/files/spb/sz2-202407-food-ru.pdf',
          ru: 'https://subzerosushi.ru/static/files/spb/sz2-202407-food-ru.pdf',
        },
        alco: 'https://subzerosushi.ru/static/files/spb/sz2-202407-alco.pdf',
      },
      photos: {
        main: 'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-001.jpg',
        gallery: [
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-001.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-002.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-003.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-004.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-005.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-006.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-007.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-008.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-009.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-010.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-2/sz2-011.jpg',
        ],
      },
      map: {
        center: [59.97752, 30.31654],
      },
    },
    bar: {
      name: 'city.spb.bar.name',
      address: 'city.spb.bar.address',
      phone: '+7 812 944 00 40',
      open: ['hours.mon-sun-12-00'],
      menu: {
        food: 'https://subzerosushi.ru/static/files/spb/sz-bar-202407-food-ru.pdf',
        alco: 'https://subzerosushi.ru/static/files/spb/sz-bar-202407-alco.pdf',
      },
      photos: {
        main: 'https://subzerosushi.ru/static/images/spb/subzero-bar/sz-bar-001.jpg',
        gallery: [
          'https://subzerosushi.ru/static/images/spb/subzero-bar/sz-bar-001.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-bar/sz-bar-002.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-bar/sz-bar-003.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-bar/sz-bar-004.jpg',
          'https://subzerosushi.ru/static/images/spb/subzero-bar/sz-bar-005.jpg',
        ],
      },
      map: {
        center: [59.9624505, 30.2882374],
      },
    },
    market: {
      name: 'city.spb.market.name',
      address: 'city.spb.market.address',
      phone: '+7 812 920 11 91',
      open: ['hours.mon-sun-11-22-23'],
      menu: {
        menu: 'https://subzerosushi.ru/static/files/spb/sz-market-202405.pdf',
      },
      photos: {
        main: 'https://subzerosushi.ru/static/images/spb/market/market-001.jpg',
        gallery: [
          'https://subzerosushi.ru/static/images/spb/market/market-001.jpg',
          'https://subzerosushi.ru/static/images/spb/market/market-002.jpg',
          'https://subzerosushi.ru/static/images/spb/market/market-003.jpg',
        ],
      },
      map: {
        center: [59.93954, 30.28449],
      },
    },
    market2: {
      name: 'city.spb.market2.name',
      address: 'city.spb.market2.address',
      phone: '+7 812 926 12 92',
      open: ['hours.mon-sun-11-22-23'],
      menu: {
        menu: 'https://subzerosushi.ru/static/files/spb/sz-market-202405.pdf',
      },
      photos: {
        main: 'https://subzerosushi.ru/static/images/filler.jpg',
      },
      map: {
        center: [59.8795795, 30.3212136],
      },
    },
    delivery: {
      name: 'common.delivery',
      address: 'https://subzerosushi.ru/shop',
      phone: '+7 812 678 99 55',
      menu: {
        'Go!': 'https://subzerosushi.ru/shop',
      },
      photos: {
        main: 'https://subzerosushi.ru/static/images/filler.jpg',
      },
    },
  },
  moscow: {
    subzero: {
      name: 'city.moscow.subzero.name',
      address: 'city.moscow.subzero.address',
      phone: '+7 495 922 64 38',
      open: [
        'hours.mon-sun-12-00',
        // "hours.fri-sat-12-02",
        // "hours.special_mondays",
      ],
      menu: [
        {
          food: {
            en: 'https://subzerosushi.ru/static/files/moscow/szm-202408-food-ru.pdf',
            ru: 'https://subzerosushi.ru/static/files/moscow/szm-202408-food-ru.pdf',
          },
          alco: 'https://subzerosushi.ru/static/files/moscow/szm-202408-alco.pdf',
        },
        // {
        //   izakaya:
        //     'https://subzerosushi.ru/static/files/moscow/sz1-202102-izakaya.pdf',
        // },
      ],
      photos: {
        main: 'https://subzerosushi.ru/static/images/moscow/subzero/sz1-001.jpg',
        gallery: [
          'https://subzerosushi.ru/static/images/moscow/subzero/sz1-001.jpg',
          'https://subzerosushi.ru/static/images/moscow/subzero/sz1-002.jpg',
          'https://subzerosushi.ru/static/images/moscow/subzero/sz1-003.jpg',
          'https://subzerosushi.ru/static/images/moscow/subzero/sz1-004.jpg',
        ],
      },
      map: {
        center: [55.76811, 37.61443],
      },
    },
  },
};

export default Cities;
